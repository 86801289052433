<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.name"
        placeholder="商家名称"
        style="width: 180px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.examine_status"
        placeholder="审核状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option label="待审核" :value="1" />
        <el-option label="审核成功" :value="2" />
        <el-option label="已驳回" :value="3" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <!--      <span class="filter-item">-->
      <!--        <download-excel-->
      <!--            :fields="json_fields"-->
      <!--            :fetch="handleExport"-->
      <!--            type="xls"-->
      <!--            name="寄卖订单信息.xls"-->
      <!--            worksheet="寄卖订单信息"-->

      <!--        >-->
      <!--&lt;!&ndash;      :before-generate="startPost"-->
      <!--          :before-finish="endPost"                :header="header"&ndash;&gt;-->
      <!--          <el-button type="warning" :loading="exportLoading">-->
      <!--            导出订单信息-->
      <!--          </el-button>-->
      <!--        </download-excel>-->
      <!--      </span>-->
    </div>
    <div class="filter-container">
      <!-- <el-radio-group v-model="listQuery.status" size="small" @change="handleFilter">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button label="1">未匹配</el-radio-button>
        <el-radio-button label="2">待买家打款</el-radio-button>
        <el-radio-button label="3">已完成</el-radio-button>
        <el-radio-button label="4">已取消</el-radio-button>
      </el-radio-group> -->
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="95">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column align="center" label="ID" width="95" v-if="false">
        <template slot-scope="scope">
          {{ scope.row.id }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>

      <el-table-column label="头像" min-width="60" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.user ? scope.row.user.avatar : '#'"
            class="user-avatar"
          />
        </template>
      </el-table-column>
      <el-table-column label="用户ID" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.user_id }}
        </template>
      </el-table-column>
      <el-table-column label="手机号" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.account : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="昵称" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.user ? scope.row.user.nickname : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="LOGO" min-width="60" align="center">
        <template slot-scope="scope">
          <img
            :src="scope.row.logo ? JSON.parse(scope.row.logo)[0] : '#'"
            class="user-avatar"
            v-if="scope.row.logo"
          /><i v-else>-</i>
        </template>
      </el-table-column>
      <el-table-column label="企业名称" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="企业营业资质" min-width="180" align="center">
        <template slot-scope="scope">
          <el-image
            :src="
              scope.row.business_license ? scope.row.business_license[0] : '#'
            "
            style="width: 100px; height: 100px"
            :preview-src-list="
              scope.row.business_license ? scope.row.business_license : '#',
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="法人姓名" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.id_card_name }}
        </template>
      </el-table-column>
      <el-table-column label="法人身份证号" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.id_card_no }}
        </template>
      </el-table-column>
      <el-table-column label="法人身份证照片" min-width="180" align="center">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.id_card_images ? scope.row.id_card_images[0] : '#'"
            style="width: 100px; height: 100px"
            :preview-src-list="
              scope.row.id_card_images ? scope.row.id_card_images : '#',
            "
          />
        </template>
      </el-table-column>
      <el-table-column label="手续费" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.commission_rate || "-" }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.examine_status === 1" type="warning">
            审核中</el-tag
          >
          <el-tag v-else-if="scope.row.examine_status === 2" type="success"
            >审核成功</el-tag
          >
          <el-tag v-else-if="scope.row.examine_status === 3" type="danger"
            >已驳回</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="拒绝原因" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.reject_reason || "-" }}
        </template>
      </el-table-column>

      <el-table-column label="创建时间" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>

      <el-table-column label="更新时间" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.updated_at }}
        </template>
      </el-table-column>

      <el-table-column
        label="操作"
        width="150"
        align="center"
        class-name="fixed-height"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.examine_status === 1"
            size="mini"
            type="success"
            @click="pass(scope.row)"
            >通过</el-button
          >
          <el-button
            v-if="scope.row.examine_status === 1"
            size="mini"
            type="danger"
            @click="reject(scope.row)"
            >驳回</el-button
          >
               <el-button
            v-if="scope.row.is_on == 1"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '下架')"
            >下架</el-button
          >
          <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        user_id: "",
        phone: "",
      },
      dialogFormVisible: false,
      btnLoading: false,
      exportLoading: false,
      form: {},
      dateArr: null,
      form: {
        id: null,
        reject_reason: null,
      },
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0];
        this.listQuery.end_at = newVal[1];
      } else {
        this.listQuery.start_at = "";
        this.listQuery.end_at = "";
      }
    },
  },
  methods: {
    
    // handleExport() {
    //   this.exportLoading=true;
    //   var query =JSON.parse(JSON.stringify(this.listQuery))  ;
    //   query.limit = 99999999999999;
    //   return request({
    //     url: "/api/backend/consignment/walletList",
    //     method: "post",
    //     params: query,
    //   })
    //       .then((result) => {
    //         var json_data = result.data.data;
    //         this.header = "寄卖订单信息";
    //         return json_data;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //       .finally(() => {
    //         this.listLoading = false;
    //         this.exportLoading=false;
    //       });
    // },
    //上下架
    handleStatus(val, text) {
      
      this.$confirm("此操作将" + text + "该挂售商家, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/linkedMerchant/upDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/backend/linkedMerchant/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        console.log(response);
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    updateCashOutStatus(id, status, refuse_reason) {
      request({
        url: "/api/backend/consignment/cashOutStatus",
        method: "post",
        params: {
          cash_out_id: id,
          status: status,
          refuse_reason: refuse_reason,
        },
      }).then((response) => {
        console.log(response);
        if (response.code === 0) {
          // 弹出消息框
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        }
      });
    },
    pass(object) {
      // 弹出确认框
      this.$confirm("是否通过该申请", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/backend/linkedMerchant/pass",
            method: "post",
            data: { id: object.id },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    rejectShow(row) {
      this.resetForm();
      this.form.id = row.id;
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    reject(object) {
      // 弹出框填写拒绝原因
      this.$prompt("请输入拒绝原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator(value) {
          if (value === "" || value === null || value === undefined) {
            return "拒绝原因不能为空";
          }
        },
      })
        .then(({ value }) => {
          request({
            url: "/api/backend/linkedMerchant/reject",
            method: "post",
            data: { id: object.id, reject_reason: value },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    // saveData() {
    //   this.btnLoading = true;
    //   request({
    //     url: "/api/backend/task/save",
    //     method: "post",
    //     data: this.form,
    //   })
    //     .then((response) => {
    //       this.btnLoading = false;
    //       this.dialogFormVisible = false;
    //       this.$notify({
    //         title: "成功",
    //         message: "保存成功",
    //         type: "success",
    //         duration: 2000,
    //       });
    //       this.getList();
    //     })
    //     .catch((err) => {
    //       this.btnLoading = false;
    //     });
    // },
  },
};
</script>


<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>